.base-media {
  &__figure {
    position: relative;
    isolation: isolate;

    &:has(:is(a,button,[tabindex]).base-overlay--full:focus-visible) {
      outline: 4px solid var(--color-outline);
      outline-offset: -4px;
    }

    .base-imagelink {
      transition: opacity .25s ease;
      opacity: 0;
    }
    &:is(:hover,:focus-within,:active) {
      .base-imagelink {
        opacity: 1;
      }
    }
    picture {
      position: relative;
      z-index: -1;
      display: block;
    }
    figcaption {
      font-size: var(--font-size-smaller);
    }
    &:is(.base-media__figure--caption-hover,.base-media__figure--caption-static) {
      position: relative;
      figcaption {
        position: absolute;
        z-index: 1;
        inset: auto 0 0 0;
        background-color: rgba(var(--color-text-rgb), .8);
        color: var(--color-back);
      }
    }
    &--stretch {
      display: flex;
      align-items: center;
      justify-content: center;
      background: repeating-linear-gradient(-45deg, rgba(var(--color-text-rgb), .05) 0 5px, transparent 5px 10px);
    }
    &--caption-hover {
      overflow: hidden;
      figcaption {
        transition: transform .25s ease, opacity .25s linear;
        opacity: 0;
        transform: translateY(100%);
      }
      &:hover {
        figcaption {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
    &--hover-zoom {
      > picture {
        overflow: hidden;
      }
      > picture img {
        transition: transform .25s ease;
      }
      &:is(:hover,:focus-within,:active) {
        > picture img {
          transform: scale(1.1) rotate(3deg);
        }
      }
    }
  }
  &.base-grid.base-media--auto-dense {
    --base-media-auto-span: 1;
    @for $i from 2 through 12 {
      &[data-cols="#{$i}"] {
        @for $i2 from 1 through $i - 1 {
          > *:last-of-type:nth-child(#{$i}n + #{$i2}) {
            // span remaining cols
            // in case 3n+1 span 3, 3n+2 span 2
            // in case 4n+1 span 4, 4n+2 span 3, 4n+3 span 2
            --base-media-auto-span: #{$i - $i2 + 1};
          }
        }
      }
    }
    > *:last-of-type {
      grid-column: span var(--base-media-auto-span);
    }
  }
}
